import { useMemo } from 'react';
import { Link } from 'react-router-dom';
// Local imports
import classes from './index.module.scss';
import { Table } from './types';

function PriceListPage() {
	// CONSTANTS
	const priceTables = useMemo<Table[]>(
		() => [
			{
				title: 'Kezelések',
				rows: [
					['Arctisztítás (tini):', '10 000.-'],
					['Arctisztítás (felnőtt) - letisztítás, radírozás, gőzöléses felpuhítás masszírozással, tisztítás, ampulla, pakolás:', '13 500.-'],
					['Arctisztítás + vio:', '13 700.-'],
					['Arctisztítás + iontós hatóanyag-bevitel:', '15 200.-'],
					['Arctisztítás + thermos hatóanyag-bevitel:', '14 300.-'],
					['Arctisztítás + hidrodermabrázió:', '14 300.-'],
					['Arctisztítás + mikrodermabrázió:', '15 500.-'],
					['Arctisztítás + mikrodermabrázió + mezoterápia:', '22 900.-'],
					['Arctisztítás + mikrodermabrázió + iontoforézis:', '17 000.-'],
					['Hátkezelés (területtől függően):', '10 000 -', '16 000.-'],
					['AHA gyümölcssavas hámlasztás:', '16 500.-'],
					['AHA gyümölcssavas hámlasztás (prémium):', '19 500.-'],
					['Oxygen therapy', '15 500.-'],
					['Collagen anti-ageing kezelés', '14 300.-'],
					['3 Peptid anti-ageing ránctalanító lifting kezelés', '17 500.-'],
					['Arctisztítás + szemöldökszedés:', '14 300.-'],
					['Arctisztítás + szempillafestés:', '15 000.-'],
					['Arctisztítás + szemöldökszedés + szempillafestés:', '15 900.-'],
					['Arctisztítás + szempilla-, szemöldök festés és szedés:', '16 900.-'],
				],
			},
			{
				title: 'Frissítő kezelések',
				rows: [
					['Arcmasszázs + radír:', '5 900.-'],
					['Dekoltázsmasszázs + radír:', '7 100.-'],
					['Arcmasszázs + radír + pakolás:', '8 300.-'],
					['Arcmasszázs + mikrodermabrázió + pakolás:', '10 700.-'],
					['Arcmasszázs + mikrodermabrázió + iontoforézis:', '15 300.-'],
					['Dekoltázsmasszázs + radír + pakolás:', '11 400.-'],
					['Dekoltázsmasszázs + mikrodermabrázió + pakolás:', '16 000.-'],
					['Arcmasszázs + mikrodermabrázió + arcmezo', '19 800.-'],
					['Radír (mechanikus):', '1 200.-'],
					['Radír (enzimes):', '1 700.-'],
					['Pakolások:', '2 200 -', '4 400.-'],
					['Vio:', '1000.-'],
					['Mikroforézis (hatóanyagtól függően):', '3 800 -', '5 000.-'],
					['Mikrodermabrázió arc + nyak + dekoltázs:', '4 700 -', '5 700.-'],
					['Hidrodermabrázió:', '4 300.-'],
				],
			},
			{
				title: 'Gyantázások',
				rows: [
					['Szemöldök:', '1 600.-'],
					['Bajusz:', '1 100.-'],
					['Áll:', '700.-'],
					['Arc:', '1 900.-'],
					['Hónalj:', '2 600.-'],
					['Kar:', '3 000.-'],
					['Hónalj + kar:', '5 100.-'],
					['Fazon:', '3 100.-'],
					['Fazon (teljes - elöl):', '4 900.-'],
					['Fazon (teljes - elöl + popsi):', '6 200.-'],
					['Láb (térdig):', '3 800.-'],
					['Láb (végig):', '6 800.-'],
					['Comb:', '4 200.-'],
					['Láb (végig) + fazon:', '9 100.-'],
					['Láb (végig) + teljes fazon:', '9 800.-'],
					['Hát:', '4 800.-'],
					['Mellkas:', '3 600.-'],
					['Has:', '2 400.-'],
					['Mellkas + has:', '5 300.-'],
				],
			},
			{
				title: 'Festések',
				rows: [
					['Szempillafestés:', '2 200.-'],
					['Szemöldökfestés:', '1 400.-'],
					['Szempilla,- szemöldökfestés és szedés:', '4 800.-'],
					['Szemöldökszedés (kézi):', '1 300.-'],
				],
			},
			{
				title: 'Sminkek',
				rows: [
					['Nappali:', '7 000.-'],
					['Alkalmi:', '8 000.-'],
					['Mennyasszonyi:', '15 500.-'],
					['Szempilladauer:', '6 800.-'],
				],
			},
			{
				title: 'Testmasszázs',
				rows: [
					['Karok (30 perc):', '5 500.-'],
					['Lábak (30 perc):', '6 600.-'],
					['Hát (40 perc):', '7 500.-'],
					['Hát + kar:', '9 500.-'],
					['Egész test (1,5 óra):', '10 500.-'],
				],
			},
		],
		[],
	);

	// RENDERES
	const priceList = useMemo<JSX.Element[]>(
		() =>
			priceTables.map((table, idx) => (
				<table key={`priceTable-${idx}`}>
					<thead>
						<tr>
							<th colSpan={2}>{table.title}</th>
						</tr>
					</thead>
					<tbody>
						{table.rows.map((row, idx) => (
							<tr key={`tableRow-${idx}`} className={classes.tableRow}>
								<td>{row?.[0]}</td>
								<td>
									<span>{row?.[1]}</span>
									{row?.[2] && <span>&nbsp;{row?.[2]}</span>}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)),
		[priceTables],
	);

	return (
		<div className={classes.priceListPage}>
			<main>
				<h1>Árlista (2025)</h1>
				{priceList}
			</main>
			<footer>
				<Link to="/">
					<button>Vissza a főoldalra</button>
				</Link>
			</footer>
		</div>
	);
}

export default PriceListPage;
